import { clearlongterm } from './use-longterm-storage'

const api = useApi()
const longterm = useLongtermStorage()
const session = useSessionStorage()

export default () => {
  // is the current user authenticated?
  const isAuthenticated = (): boolean => !!longterm.user && !!longterm.token

  // user data (fall back to autocomplete data)
  const getUser = (): User =>
    longterm.user ??
    longterm.softAuthenticatedUser ??
    longterm.autocomplete?.user ?? {
      id: null,
      idh: longterm.userIdh ?? null,
      firstName: '',
      lastName: '',
      birthDate: '',
      email: '',
      phone: '',
    }

  // gatekeeper
  function gatekeeper(): boolean {
    if (!isAuthenticated()) {
      registerRedirectDestination()

      setTimeout(() => {
        navigateTo(getRoute('account/login'), { external: true })
      }, 500)

      throw new Error('User is not authenticated')
    }

    return true
  }

  // register redirect destination
  function registerRedirectDestination(): void {
    session.redirect = globalThis.location.pathname
  }

  // redirect after authentication
  function redirect() {
    if (session.redirect) {
      const redirect = session.redirect
      session.redirect = null

      navigateTo(redirect, { external: true })
    } else {
      navigateTo(getRoute(''), { external: true })
    }
  }

  // soft authenticate with a user object
  async function softAuthenticate(user: User): Promise<boolean> {
    try {
      longterm.softAuthenticatedUser = { ...user }
      return true
    } catch (error) {
      console.error(error)
    }

    return false
  }

  // authenticate with email and password
  async function authenticateWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<boolean> {
    try {
      const response = await api.authentication.loginWithEmailAndPassword(
        email,
        password,
      )

      if (!response.error) {
        longterm.token = response.data
        await refresh()

        return true
      }
    } catch (error) {
      console.error(error)
    }

    return false
  }

  // authenticate with service and code
  async function authenticateServiceAndCode(
    service: string,
    code: string,
  ): Promise<boolean> {
    try {
      const response = await api.authentication.loginWithServiceAndCode(
        service,
        code,
      )

      if (!response.error) {
        longterm.token = response.data
        await refresh()

        return true
      }
    } catch (error) {
      console.error(error)
    }

    return false
  }

  // authenticate with token
  async function authenticateWithToken(token: string): Promise<boolean> {
    try {
      longterm.token = token
      await refresh()
      return true
    } catch (error) {
      console.error(error)
    }

    return false
  }

  // deauthenticate
  async function deauthenticate(): Promise<void> {
    setTimeout(() => {
      clearlongterm()
      clearSessionStorage()
      globalThis.location.reload()
    }, 0)
  }

  // register
  async function register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    birthDate: Date,
    zip: string,
    redirectUrl?: string,
  ): Promise<boolean> {
    try {
      const ipResponse = await api.external.getIpInfo()
      const registerResponse = await api.user.create(
        email,
        password,
        firstName,
        lastName,
        birthDate,
        zip,
        ipResponse.data.value?.ip ?? '0.0.0.0',
        redirectUrl,
      )

      if (!registerResponse.error) {
        return true
      }
    } catch (error) {
      console.error(error)
    }

    return false
  }

  // refresh user data
  async function refresh(): Promise<void> {
    // get complete user data
    if (longterm.token) {
      const response = await api.user.get(false)

      if (!response.error.value) {
        longterm.user = response.data.value
      }
    }

    // get basic user data
    else if (longterm.userIdh) {
      const response = await api.user.getBasic(longterm.userIdh, false)

      if (!response.error.value) {
        softAuthenticate(response.data.value!)
      }
    }
  }

  // initial refresh
  refresh()

  // return
  return {
    gatekeeper: gatekeeper,
    redirect: redirect,
    registerRedirectDestination: registerRedirectDestination,
    softAuthenticate: softAuthenticate,
    authenticateWithEmailAndPassword: authenticateWithEmailAndPassword,
    authenticateServiceAndCode: authenticateServiceAndCode,
    authenticateWithToken: authenticateWithToken,
    deauthenticate: deauthenticate,
    isAuthenticated: isAuthenticated,
    getUser: getUser,
    register: register,
    refresh: refresh,
  }
}
