const longterm = reactive(
  useStorageDecoder(globalThis.localStorage?.getItem('outspot-longterm') ?? ''),
)

watch(
  longterm,
  () =>
    globalThis.localStorage?.setItem(
      'outspot-longterm',
      useStorageEncoder(longterm),
    ),
  {
    deep: true,
  },
)

export function clearlongterm(): void {
  globalThis.localStorage?.setItem('outspot-longterm', useStorageEncoder({}))
}

export default () => longterm
