const authentication = useAuthentication()
const longterm = useLongtermStorage()
const session = useSessionStorage()
const api = useApi()

let userIdh: string
let mailId: string
let source: string

export default function () {
  return {
    init() {
      try {
        const params = new URLSearchParams(globalThis?.location?.search ?? '')

        userIdh =
          params.get('userid') ??
          longterm.userIdh ??
          authentication.getUser().idh
        mailId = params.get('mid') ?? session.mailId
        source = params.get('source') ?? ''

        // store session source
        if (!session.source) {
          session.source = globalThis?.location?.href ?? ''
        }

        // store lcc
        if (source) {
          longterm.lcc = longterm.lcc ?? []
          longterm.lcc.push(
            `${source}:${new Date().toISOString().substring(0, 10)}`,
          )
          longterm.lcc = longterm.lcc.slice(-10)
        }

        // store user idh
        if (userIdh) {
          longterm.userIdh = userIdh
        }

        // store mid
        if (mailId) {
          session.mailId = mailId
        }

        // update last seen
        if (userIdh) {
          api.tracking.trackSeen(userIdh)
        }
      } catch (ex) {}
    },

    async trackClick(deal?: Deal) {
      try {
        if (userIdh && !session.clickId) {
          session.clickId = (
            await api.tracking.trackClick(
              deal?.id,
              session.source,
              userIdh,
              mailId,
            )
          ).data
        }
      } catch (ex) {}
    },

    async trackEvent(event: string) {
      try {
        if (userIdh && session.clickId) {
          await api.tracking.trackEvent(event, userIdh, session.clickId)
        }
      } catch (ex) {}
    },
  }
}
