const session = reactive(
  useStorageDecoder(
    globalThis.sessionStorage?.getItem('outspot-session') ?? '',
  ),
)

watch(
  session,
  () =>
    globalThis.sessionStorage?.setItem(
      'outspot-session',
      useStorageEncoder(session),
    ),
  {
    deep: true,
  },
)

export function clearSessionStorage(): void {
  globalThis.sessionStorage?.setItem('outspot-session', useStorageEncoder({}))
}

export default () => session
