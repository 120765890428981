const cache = useCache()

const networks: ServerToServerNetworkList = [
  // TradeDoubler
  {
    token: 'tduid',
    ttl: 365 * 24 * 60 * 60 * 1000,
  },

  // Add more networks here, the token is the query parameter name, the ttl is how long it should be stored in the cache
]

function register(network: string, token: string, ttl: number) {
  cache.set(
    `server-to-server-${network}`,
    { token: token, date: new Date().toISOString().split('T')[0] },
    ttl,
  )
}

function getTokens() {
  return networks
    .map((network) => {
      const key = `server-to-server-${network.token}`

      return !cache.has(key)
        ? undefined
        : {
            token: network.token,
            data: cache.get(key),
          }
    })
    .filter((token) => !!token)
}

export default function () {
  return {
    networks: networks,
    register: register,
    getTokens: getTokens,
  }
}
