const serverToServer = useServerToServer()

export default defineNuxtRouteMiddleware((to, from) => {
  try {
    serverToServer.networks.forEach((network: ServerToServerNetwork) => {
      if (to.query[network.token] !== undefined) {
        serverToServer.register(
          network.token,
          (to.query[network.token] ?? '').toString(),
          network.ttl,
        )
      }
    })
  } catch (e) {
    console.error(e)
  }
})
