import { languages } from '../messages/languages'

export default function (): string {
  let currentLocale

  if (globalThis && globalThis.location && globalThis.location.pathname) {
    currentLocale =
      globalThis.location.pathname
        .trim()
        .split('/')
        .filter((pathnamePart: string) => pathnamePart)[0] ?? null
  }

  if (!currentLocale) {
    const tld = (globalThis.location?.hostname ?? '').split('.').pop()

    switch (tld) {
      case 'be':
        return 'nl'
      case 'nl':
        return 'nd'
      case 'de':
        return 'de'
      case 'uk':
        return 'en'
      case 'es':
        return 'es'
      case 'ie':
        return 'ie'
      case 'fr':
        return 'fra'
      case 'it':
        return 'it'
      case 'at':
        return 'at'
      case 'pl':
        return 'pl'
      case 'pt':
        return 'pt'
      case 'se':
        return 'sv'
      case 'fi':
        return 'fi'
      case 'us':
        return 'us'
    }

    return 'nl'
  }

  return currentLocale ?? languages[0] ?? 'nl'
}
