import type {
  Address,
  AddressList,
  ApiGetResponse,
  ApiResponse,
  AuthenticationServiceList,
  CouponList,
  DealList,
  IpInfo,
  ListWithMeta,
  MandateList,
  QuestionList,
  RemoteOrder,
  Review,
  ReviewType,
  SendCloudServicePoint,
  Token,
  User,
} from '~/utils/types'

const cache = useCache()
const longterm = useLongtermStorage()
const currentLocale = useCurrentLocale()

//const apiRoot = 'http://outspot-api-lea.localhost:8001/v2/public'
const apiRoot = 'https://api.outspot.be/v2/public'
const apiAppVersion = '20240101'

async function fetchHelper(path: string, options?: object): Promise<any> {
  // TODO: implement this with $fetch
  return useFetch(path, options)
}

function enrichData(data?: object): object {
  return {
    ...(data ?? {}),
    appVersion: apiAppVersion,
    isNativeApp: isApp(),
    language: currentLocale,
  }
}

function apiGetRequest<T>(
  useCache: boolean,
  path: string,
  query?: object,
  transformResponse?: CallableFunction,
): Promise<ApiGetResponse<T>> {
  query = enrichData(query)

  return new Promise((resolve) => {
    // build cache key
    const key =
      path +
      JSON.stringify(query) +
      new Date().toDateString() +
      new Date().getHours().toString() +
      (longterm.token ?? '')

    // build response
    const response: ApiGetResponse<T> = {
      data: ref(),
      stale: ref(true),
      error: ref(false),
      refresh: () => fetch(),
    }

    // fetch & refetch data
    const fetch = async () => {
      try {
        // update the data
        const { data } = await fetchHelper(
          path.includes('http') ? path : `${apiRoot}/${path}`,
          { query: query },
        )
        const dataTransformed = transformResponse
          ? transformResponse(data)
          : data

        response.data.value = dataTransformed
        response.stale.value = false
        response.error.value = false

        cache.set(key, dataTransformed)
      } catch (ex) {
        // error @TODO: this is not working
        response.error.value = true
      }

      resolve(response)
    }

    // check if we have a cached value
    const cachedValue = cache.get(key)

    if (useCache && cachedValue) {
      response.data.value = cachedValue
      resolve(response)
    }

    // initial fetch
    fetch()
  })
}

async function apiRequest<T>(
  method: RequestMethod,
  path: string,
  body?: object,
  transformResponse?: CallableFunction,
): Promise<ApiResponse<T>> {
  body = enrichData(body)

  const { data, error, status } = await fetchHelper(
    path.includes('http') ? path : `${apiRoot}/${path}`,
    { method: method, body: body },
  )

  const isError = status.value !== 'success'
  const dataTransformed = isError
    ? error.value?.data?.errors
    : transformResponse
      ? transformResponse(data)
      : data

  return {
    data: dataTransformed,
    error: isError,
  }
}

async function apiPostRequest<T>(
  path: string,
  body?: object,
  transformResponse?: CallableFunction,
): Promise<ApiResponse<T>> {
  return apiRequest<T>('POST', path, body, transformResponse)
}

async function apiPatchRequest<T>(
  path: string,
  body?: object,
  transformResponse?: CallableFunction,
): Promise<ApiResponse<T>> {
  return apiRequest<T>('PATCH', path, body, transformResponse)
}

async function apiDeleteRequest<T>(
  path: string,
  body?: object,
  transformResponse?: CallableFunction,
): Promise<ApiResponse<T>> {
  return apiRequest<T>('DELETE', path, body, transformResponse)
}

export default () => {
  return {
    deals: {
      list: (useCache: boolean = true) =>
        apiGetRequest<DealList>(
          useCache,
          `deals`,
          { token: longterm.token },
          (response: any) => response.value.data,
        ),
      get: (id: number, useCache: boolean = true) =>
        apiGetRequest<Deal>(
          useCache,
          `deals/${id}`,
          {},
          (response: any) => response.value.data,
        ),
      getBySlug: (slug: string, useCache: boolean = true) =>
        apiGetRequest<Deal>(
          useCache,
          `deals/slug/${slug}`,
          {},
          (response: any) => response.value.data,
        ),
      getBySlugForAllDomain: (slug: string, useCache: boolean = true) =>
        apiGetRequest<any>(
          useCache,
          `deals/slug-for-domain/${slug}`,
          {},
          (response: any) => response.value.data,
        ),
    },
    plus: {
      get: (userIdh?: string, useCache: boolean = true) =>
        apiGetRequest<PlusInfo>(
          useCache,
          `plus`,
          { user_idh: userIdh },
          (response: any) => response.value.data,
        ),
      create: (
        email: string,
        firstName: string,
        lastName: string,
        birthDate: Date,
        redirectUrl?: string,
        method?: string,
        componentsCardToken?: string,
        linkToken?: string,
      ) =>
        apiPostRequest<RemoteOrder>(
          'plus',
          {
            email: email,
            first_name: firstName,
            last_name: lastName,
            birth_date: birthDate
              ? parseDate(birthDate).toISOString().split('T')[0]
              : undefined,
            method: method ?? undefined,
            components_card_token: componentsCardToken ?? undefined,
            redirect_url: redirectUrl ?? undefined,
            link_token: linkToken ?? undefined,
          },
          (response: any) => response.value.data,
        ),
    },
    reviews: {
      list: (
        dealId: number,
        offset?: number,
        limit?: number,
        useCache: boolean = true,
      ) =>
        apiGetRequest<ListWithMeta<Review>>(
          useCache,
          `reviews/${dealId}/${currentLocale}`,
          { offset: offset ?? 0, limit: limit ?? 10 },
          (response: any) => ({
            data: response.value.data ?? [],
            meta: response.value.meta,
          }),
        ),
      create: (
        orderId: number,
        userId: number,
        name: string,
        type: ReviewType,
        score?: number,
        review?: string,
      ) =>
        apiPostRequest<any>(`reviews`, {
          order_id: orderId,
          user_id: userId,
          name: name,
          type: type,
          score: score ?? undefined,
          review: review ?? undefined,
        }),
      canCreate: (
        orderId: number,
        userIdh: string,
        type: ReviewType,
        useCache: boolean = true,
      ) =>
        apiGetRequest<User>(
          useCache,
          `reviews/can-create`,
          { order_id: orderId, user_idh: userIdh, type: type },
          (response: any) => response.value.data,
        ),
    },
    addresses: {
      find: (text: string, container: string, useCache: boolean = true) =>
        apiGetRequest<AddressFinderAddressList>(
          useCache,
          `addresses/find`,
          { text: text, container: container },
          (response: any) => response.value.data ?? [],
        ),
      retrieve: (id: string, useCache: boolean = true) =>
        apiGetRequest<Address>(
          useCache,
          `addresses/find/${id}`,
          { token: longterm.token },
          (response: any) => response.value.data,
        ),
      list: (useCache: boolean = true) =>
        apiGetRequest<AddressList>(
          useCache,
          `addresses`,
          { token: longterm.token },
          (response: any) => response.value.data,
        ),
      delete: (id: number) =>
        apiDeleteRequest<any>(`addresses/${id}`, { token: longterm.token }),
      create: (address: Address) =>
        apiPostRequest<Address>(
          `addresses`,
          {
            token: longterm.token,
            street: address.street,
            number: address.number,
            building: address.building,
            zip: address.zip,
            city: address.city,
            state: address.state,
            country: address.country,
            firstname: address.firstname,
            name: address.name,
            phone: address.phone,
          },
          (response: any) => response.value.data,
        ),
      update: (id: number, address: Address) =>
        apiPatchRequest<Address>(
          `addresses/${id}`,
          {
            token: longterm.token,
            street: address.street,
            number: address.number,
            building: address.building,
            zip: address.zip,
            city: address.city,
            state: address.state,
            country: address.country,
            firstname: address.firstname,
            name: address.name,
            phone: address.phone,
          },
          (response: any) => response.value.data,
        ),
    },
    mandates: {
      list: (useCache: boolean = true) =>
        apiGetRequest<MandateList>(
          useCache,
          `mandates`,
          { token: longterm.token },
          (response: any) => response.value.data,
        ),
      delete: (id: number) =>
        apiDeleteRequest<any>(`mandates/${id}`, { token: longterm.token }),
    },
    orders: {
      get: (id: number, email: string, useCache: boolean = true) =>
        apiGetRequest<RemoteOrder>(
          useCache,
          `orders/${id}`,
          { email: email },
          (response: any) => response.value.data,
        ),
      create: (
        email: string,
        dealId: number,
        source: string,
        lcc: string,
        overwriteOrderId?: number,
        step?: null | string,
      ) =>
        apiPostRequest<RemoteOrder>(
          'orders',
          {
            email: email,
            deal_id: dealId,
            source: source,
            lcc: lcc,
            overwrite_order_id: overwriteOrderId ?? undefined,
            step: step ?? undefined,
            is_test: isTest(),
          },
          (response: any) => response.value.data,
        ),
      calculateTaxes: (id: number, email: string) =>
        apiPostRequest<RemoteOrder>(
          `orders/${id}/taxes`,
          {
            email: email,
          },
          (response: any) => response.value.data,
        ),
      update: (
        id: number,
        email: string,
        firstName: string,
        lastName: string,
        phone: string,
        deliveryAddress?: Address,
        deliveryServicePoint?: SendCloudServicePoint,
        invoiceAddress?: Address,
        invoiceRequired?: boolean,
        invoiceReason?: string,
      ) =>
        apiPatchRequest<any>(
          `orders/${id}`,
          {
            email: email,
            shipping_firstname: firstName,
            shipping_name: lastName,
            shipping_phone: (deliveryAddress?.phone || null) ?? phone,
            ...(deliveryAddress
              ? {
                  shipping_street: deliveryAddress.street,
                  shipping_number: deliveryAddress.number,
                  shipping_building: deliveryAddress.building,
                  shipping_zip: deliveryAddress.zip,
                  shipping_city: deliveryAddress.city,
                  shipping_state: deliveryAddress.state,
                  shipping_country: deliveryAddress.country,
                }
              : {}),
            shipping_service_point: deliveryServicePoint?.id,
            invoice_needed: invoiceRequired ?? false,
            invoice_purpose: invoiceReason ?? '',
            ...(invoiceAddress && invoiceRequired
              ? {
                  // @ts-ignore
                  invoice_name: invoiceAddress.company_name,
                  // @ts-ignore
                  invoice_tax_id: invoiceAddress.tax_id,
                  invoice_address:
                    invoiceAddress.street +
                    ' ' +
                    invoiceAddress.number +
                    ' ' +
                    invoiceAddress.building,
                  invoice_zip: invoiceAddress.zip,
                  invoice_city:
                    invoiceAddress.city +
                    ' ' +
                    invoiceAddress.country +
                    ' ' +
                    invoiceAddress.state,
                }
              : {}),
            is_test: isTest(),
          },
          (response: any) => response.value.data,
        ),
      addOption: (
        id: number,
        email: string,
        optionId: number,
        quantity: number,
        extraFields: any,
      ) =>
        apiPostRequest<any>(
          `orders/${id}/details`,
          {
            email: email,
            option_id: optionId,
            option_quantity: quantity,
            option_extra_field_values: extraFields,
            is_test: isTest(),
          },
          (response: any) => response.value.data,
        ),
      getCoupon: (
        id: number,
        code: string,
        email: string,
        useCache: boolean = true,
      ) =>
        apiGetRequest<Coupon>(
          useCache,
          `orders/${id}/coupons/${code}`,
          { email: email },
          (response: any) => ({
            code: response.value.code,
            amount: parseFloat(response.value.amount),
            cumulative: response.value.cumulative,
            percentage: response.value.percentage,
          }),
        ),
      finish: (
        id: number,
        email: string,
        redirectUrl?: string,
        couponCodes?: Array<string>,
        method?: string,
        mandate?: string,
        applePayPaymentToken?: string,
        componentsCardToken?: string,
        shouldCreateMandate?: boolean,
      ) =>
        apiPostRequest<any>(
          `orders/${id}/payments`,
          {
            email: email,
            coupon_codes: couponCodes ?? [],
            mandate: mandate ?? undefined,
            apple_pay_payment_token: applePayPaymentToken ?? undefined,
            components_card_token: componentsCardToken ?? undefined,
            method: method ?? undefined,
            redirect_url: redirectUrl ?? undefined,
            is_test: isTest(),
            create_mandate: shouldCreateMandate ?? false,
          },
          (response: any) => response.value,
        ),
      list: (useCache: boolean = true) =>
        apiGetRequest<RemoteOrderList>(
          useCache,
          `orders`,
          { token: longterm.token },
          (response: any) => response.value.data,
        ),
      getNuveiConfig: () =>
        apiGetRequest<NuveiConfig>(
          false,
          `orders/sessions/nuvei`,
          { token: longterm.token },
          (response: any) => response.value.data,
        ),
    },
    support: {
      list: (useCache: boolean = true) =>
        apiGetRequest<QuestionList>(
          useCache,
          `support`,
          {},
          (response: any) => response.value.data,
        ),
      createRequest: (
        email: string,
        firstName: string,
        lastName: string,
        phone: string,
        extraInfo: string,
        subject: string,
        message: string,
      ) =>
        apiPostRequest<any>(`support/requests`, {
          email: email,
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          order_code: extraInfo,
          message: message,
          subject: subject,
        }),
      getPages: (useCache: boolean = true) =>
        apiGetRequest<SupportPageList>(
          useCache,
          `support/pages`,
          {},
          (response: any) => response.value.data,
        ),
      getNeedHelpText(useCache: boolean = true) {
        return apiGetRequest<string>(
          useCache,
          `support/need-help-text`,
          {},
          (response: any) => response.value.data,
        )
      },
    },
    authentication: {
      listServices: (redirectUrl?: string, useCache: boolean = true) =>
        apiGetRequest<AuthenticationServiceList>(
          useCache,
          `authentication/services`,
          { redirect_url: redirectUrl },
          (response: any) => response.value.data,
        ),
      loginWithEmailAndPassword: (email: string, password: string) =>
        apiPostRequest<ApiResponse<Token>>(
          'authentication',
          { username: email, password: password },
          (response: any) => response.value.token,
        ),
      loginWithServiceAndCode: (service: string, code: string) =>
        apiPostRequest<ApiResponse<Token>>(
          'authentication',
          { service: service, code: code },
          (response: any) => response.value.token,
        ),
    },
    user: {
      get: (useCache: boolean = true) =>
        apiGetRequest<User>(
          useCache,
          `users/me`,
          { token: longterm.token },
          (response: any) => response.value.user,
        ),
      getBasic: (idh: string, useCache: boolean = true) =>
        apiGetRequest<User>(
          useCache,
          `users/me/basic`,
          { user_idh: idh },
          (response: any) => response.value.user,
        ),
      resetPassword: (email: string, redirectUrl?: string) =>
        apiPostRequest<any>('users/password-resets', {
          email: email,
          redirect_url: redirectUrl ?? undefined,
        }),
      confirmResetPassword: (
        userId: number,
        token: string,
        newPassword: string,
      ) =>
        apiPostRequest<ApiResponse<Token>>(
          `users/password-resets/confirmations/${userId}`,
          {
            password: newPassword,
            passwordConfirmation: newPassword,
            token: token,
          },
          (response: any) => response.value.token,
        ),
      updatePassword: (newPassword: string) =>
        apiPatchRequest<any>(
          `users/me`,
          {
            token: longterm.token,
            password: newPassword,
            passwordConfirmation: newPassword,
          },
          (response: any) => response.value.token,
        ),
      updateInfo: (
        email: string,
        firstName: string,
        lastName: string,
        birthDate: Date,
        phone: string,
      ) =>
        apiPatchRequest<any>(`users/me`, {
          token: longterm.token,
          email: email,
          firstName: firstName,
          lastName: lastName,
          birthDate: birthDate
            ? parseDate(birthDate).toISOString().split('T')[0]
            : undefined,
          phone: phone,
        }),
      create: (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        birthDate: Date,
        zip: string,
        ip: string,
        redirectUrl?: string,
      ) =>
        apiPostRequest<any>('users', {
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
          passwordConfirmation: password,
          birthDate: birthDate
            ? parseDate(birthDate).toISOString().split('T')[0]
            : undefined,
          zip: zip,
          ip: ip,
          redirect_url: redirectUrl ?? undefined,
        }),
      confirmCreate: (userId: number, token: string) =>
        apiPostRequest<ApiResponse<Token>>(
          `users/confirmations/${userId}`,
          { token: token },
          (response: any) => response.value.token,
        ),
      cancelPlus: () =>
        apiDeleteRequest<any>(`users/me/plus`, { token: longterm.token }),
    },
    coupons: {
      list: (useCache: boolean = true) =>
        apiGetRequest<CouponList>(
          useCache,
          `coupons`,
          { token: longterm.token },
          (response: any) => response.value.data ?? [],
        ),
      claim: (code: string) =>
        apiPostRequest<any>(`coupons/claim`, {
          token: longterm.token,
          code: code,
        }),
    },
    subscriptions: {
      list: (useCache: boolean = true) =>
        apiGetRequest<SubscriptionList>(
          useCache,
          `subscriptions`,
          { token: longterm.token },
          (response: any) => response.value.data ?? [],
        ),
      create: (email: string, source: string) =>
        apiPostRequest<any>(
          'subscriptions',
          {
            email: email,
            source: source,
          },
          (response: any) => response.value.user,
        ),
      confirm: (
        token: string,
        firstName?: string,
        lastName?: string,
        birthDate?: Date,
        zip?: string,
      ) =>
        apiPostRequest<User>(
          `subscriptions/${token}/confirmations`,
          {
            first_name: firstName,
            last_name: lastName,
            birth_date: birthDate
              ? parseDate(birthDate).toISOString().split('T')[0]
              : undefined,
            zip: zip,
          },
          (response: any) => response.value.data,
        ),
      update: (type: string, frequency: number) =>
        apiPatchRequest<any>(`subscriptions/${type}`, {
          token: longterm.token,
          frequency: frequency,
        }),
    },
    tracking: {
      trackSeen: (userIdh?: string) =>
        apiPostRequest<any>(`tracking/seen`, { user_idh: userIdh }),
      trackClick: (
        dealId?: number,
        url?: string,
        userIdh?: string,
        mailId?: string,
      ) =>
        apiPostRequest<any>(
          `tracking/clicks`,
          { user_idh: userIdh, mail_id: mailId, deal_id: dealId, url: url },
          (response: any) => response.value.data.clickId,
        ),
      trackEvent: (event: string, userIdh?: string, clickId?: number) =>
        apiPostRequest<any>(`tracking/events`, {
          event: event,
          user_idh: userIdh,
          click_id: clickId,
        }),
      trackServerToServer: (orderId: number, tokens: any) =>
        apiPostRequest<any>(`tracking/server`, {
          order_id: orderId,
          tokens: tokens,
        }),
    },
    external: {
      getIpInfo: (useCache: boolean = true) =>
        apiGetRequest<IpInfo>(
          useCache,
          `https://ipapi.co/json/`,
          {},
          (response: any) => response.value,
        ),
    },
    unsubscription: {
      unsubscribe: (userIdh?: string) =>
        apiPostRequest<any>(`unsubscribe/user`, { user_idh: userIdh }),
      report: (userIdh?: string) =>
        apiPostRequest<any>(`unsubscribe/report`, { user_idh: userIdh }),
      update: (userIdh?: string, reason?: string, frequency?: number) =>
        apiPostRequest<any>(`unsubscribe/user/update`, {
          user_idh: userIdh,
          reason: reason,
          frequency: frequency,
        }),
      getCoreg: (userIdh?: string) =>
        apiPostRequest<any>(`unsubscribe/coreg`, { user_idh: userIdh }),
    },
  }
}
