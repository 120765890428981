import { default as aboutU06dK8EfeHMeta } from "/app/pages/[language]/about.vue?macro=true";
import { default as confirmQruBTrnQfiMeta } from "/app/pages/[language]/account/confirm.vue?macro=true";
import { default as forgotvRUVpDYO6aMeta } from "/app/pages/[language]/account/forgot.vue?macro=true";
import { default as loginJjwhg7UbzPMeta } from "/app/pages/[language]/account/login.vue?macro=true";
import { default as my_45couponsv4cGYS5hI0Meta } from "/app/pages/[language]/account/my-coupons.vue?macro=true";
import { default as my_45infoTuEf3x5gs7Meta } from "/app/pages/[language]/account/my-info.vue?macro=true";
import { default as indexPeV8EcUHGxMeta } from "/app/pages/[language]/account/my-orders/[id]/index.vue?macro=true";
import { default as reviewibljo3Fv3mMeta } from "/app/pages/[language]/account/my-orders/[id]/review.vue?macro=true";
import { default as indexQunHSop19KMeta } from "/app/pages/[language]/account/my-orders/index.vue?macro=true";
import { default as my_45outspot_45plusVEYE8H5v8vMeta } from "/app/pages/[language]/account/my-outspot-plus.vue?macro=true";
import { default as notificationssFOJjRj56eMeta } from "/app/pages/[language]/account/notifications.vue?macro=true";
import { default as passwordJWE1zycUbxMeta } from "/app/pages/[language]/account/password.vue?macro=true";
import { default as registerwN7J2Bcl58Meta } from "/app/pages/[language]/account/register.vue?macro=true";
import { default as indexkimvN0QfZkMeta } from "/app/pages/[language]/account/unsubscribe/index.vue?macro=true";
import { default as report036mnBh6zZMeta } from "/app/pages/[language]/account/unsubscribe/report.vue?macro=true";
import { default as claim_45couponK1SRXl4Ih4Meta } from "/app/pages/[language]/claim-coupon.vue?macro=true";
import { default as indexf29a4ekf9KMeta } from "/app/pages/[language]/deals/[slug]/index.vue?macro=true";
import { default as details1FmwBHFAceMeta } from "/app/pages/[language]/deals/[slug]/order/details.vue?macro=true";
import { default as finishedxAcTXOQKhnMeta } from "/app/pages/[language]/deals/[slug]/order/finished.vue?macro=true";
import { default as indexGZ2CtzegwyMeta } from "/app/pages/[language]/deals/[slug]/order/index.vue?macro=true";
import { default as reviewsHOwNJhCx6yMeta } from "/app/pages/[language]/deals/[slug]/reviews.vue?macro=true";
import { default as _91slug_9379r4IcNSrkMeta } from "/app/pages/[language]/help/[slug].vue?macro=true";
import { default as indexNLQIHJg711Meta } from "/app/pages/[language]/help/index.vue?macro=true";
import { default as indexS5WyhbOij0Meta } from "/app/pages/[language]/index.vue?macro=true";
import { default as nuvei_45helperSjbhIKNqixMeta } from "/app/pages/[language]/nuvei-helper.vue?macro=true";
import { default as indexeDFrCJmGWCMeta } from "/app/pages/[language]/onboard/index.vue?macro=true";
import { default as notificationsnChuxWrFC1Meta } from "/app/pages/[language]/onboard/notifications.vue?macro=true";
import { default as indexkGe0ECwdXVMeta } from "/app/pages/[language]/outspot-plus/index.vue?macro=true";
import { default as detailsdt90XU3RNkMeta } from "/app/pages/[language]/outspot-plus/order/details.vue?macro=true";
import { default as finishedADdDBx7oc1Meta } from "/app/pages/[language]/outspot-plus/order/finished.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as readywgYXjzIKUXMeta } from "/app/pages/ready.vue?macro=true";
export default [
  {
    name: aboutU06dK8EfeHMeta?.name ?? "language-about",
    path: aboutU06dK8EfeHMeta?.path ?? "/:language()/about",
    meta: aboutU06dK8EfeHMeta || {},
    alias: aboutU06dK8EfeHMeta?.alias || [],
    redirect: aboutU06dK8EfeHMeta?.redirect,
    component: () => import("/app/pages/[language]/about.vue").then(m => m.default || m)
  },
  {
    name: confirmQruBTrnQfiMeta?.name ?? "language-account-confirm",
    path: confirmQruBTrnQfiMeta?.path ?? "/:language()/account/confirm",
    meta: confirmQruBTrnQfiMeta || {},
    alias: confirmQruBTrnQfiMeta?.alias || [],
    redirect: confirmQruBTrnQfiMeta?.redirect,
    component: () => import("/app/pages/[language]/account/confirm.vue").then(m => m.default || m)
  },
  {
    name: forgotvRUVpDYO6aMeta?.name ?? "language-account-forgot",
    path: forgotvRUVpDYO6aMeta?.path ?? "/:language()/account/forgot",
    meta: forgotvRUVpDYO6aMeta || {},
    alias: forgotvRUVpDYO6aMeta?.alias || [],
    redirect: forgotvRUVpDYO6aMeta?.redirect,
    component: () => import("/app/pages/[language]/account/forgot.vue").then(m => m.default || m)
  },
  {
    name: loginJjwhg7UbzPMeta?.name ?? "language-account-login",
    path: loginJjwhg7UbzPMeta?.path ?? "/:language()/account/login",
    meta: loginJjwhg7UbzPMeta || {},
    alias: loginJjwhg7UbzPMeta?.alias || [],
    redirect: loginJjwhg7UbzPMeta?.redirect,
    component: () => import("/app/pages/[language]/account/login.vue").then(m => m.default || m)
  },
  {
    name: my_45couponsv4cGYS5hI0Meta?.name ?? "language-account-my-coupons",
    path: my_45couponsv4cGYS5hI0Meta?.path ?? "/:language()/account/my-coupons",
    meta: my_45couponsv4cGYS5hI0Meta || {},
    alias: my_45couponsv4cGYS5hI0Meta?.alias || [],
    redirect: my_45couponsv4cGYS5hI0Meta?.redirect,
    component: () => import("/app/pages/[language]/account/my-coupons.vue").then(m => m.default || m)
  },
  {
    name: my_45infoTuEf3x5gs7Meta?.name ?? "language-account-my-info",
    path: my_45infoTuEf3x5gs7Meta?.path ?? "/:language()/account/my-info",
    meta: my_45infoTuEf3x5gs7Meta || {},
    alias: my_45infoTuEf3x5gs7Meta?.alias || [],
    redirect: my_45infoTuEf3x5gs7Meta?.redirect,
    component: () => import("/app/pages/[language]/account/my-info.vue").then(m => m.default || m)
  },
  {
    name: indexPeV8EcUHGxMeta?.name ?? "language-account-my-orders-id",
    path: indexPeV8EcUHGxMeta?.path ?? "/:language()/account/my-orders/:id()",
    meta: indexPeV8EcUHGxMeta || {},
    alias: indexPeV8EcUHGxMeta?.alias || [],
    redirect: indexPeV8EcUHGxMeta?.redirect,
    component: () => import("/app/pages/[language]/account/my-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: reviewibljo3Fv3mMeta?.name ?? "language-account-my-orders-id-review",
    path: reviewibljo3Fv3mMeta?.path ?? "/:language()/account/my-orders/:id()/review",
    meta: reviewibljo3Fv3mMeta || {},
    alias: reviewibljo3Fv3mMeta?.alias || [],
    redirect: reviewibljo3Fv3mMeta?.redirect,
    component: () => import("/app/pages/[language]/account/my-orders/[id]/review.vue").then(m => m.default || m)
  },
  {
    name: indexQunHSop19KMeta?.name ?? "language-account-my-orders",
    path: indexQunHSop19KMeta?.path ?? "/:language()/account/my-orders",
    meta: indexQunHSop19KMeta || {},
    alias: indexQunHSop19KMeta?.alias || [],
    redirect: indexQunHSop19KMeta?.redirect,
    component: () => import("/app/pages/[language]/account/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: my_45outspot_45plusVEYE8H5v8vMeta?.name ?? "language-account-my-outspot-plus",
    path: my_45outspot_45plusVEYE8H5v8vMeta?.path ?? "/:language()/account/my-outspot-plus",
    meta: my_45outspot_45plusVEYE8H5v8vMeta || {},
    alias: my_45outspot_45plusVEYE8H5v8vMeta?.alias || [],
    redirect: my_45outspot_45plusVEYE8H5v8vMeta?.redirect,
    component: () => import("/app/pages/[language]/account/my-outspot-plus.vue").then(m => m.default || m)
  },
  {
    name: notificationssFOJjRj56eMeta?.name ?? "language-account-notifications",
    path: notificationssFOJjRj56eMeta?.path ?? "/:language()/account/notifications",
    meta: notificationssFOJjRj56eMeta || {},
    alias: notificationssFOJjRj56eMeta?.alias || [],
    redirect: notificationssFOJjRj56eMeta?.redirect,
    component: () => import("/app/pages/[language]/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: passwordJWE1zycUbxMeta?.name ?? "language-account-password",
    path: passwordJWE1zycUbxMeta?.path ?? "/:language()/account/password",
    meta: passwordJWE1zycUbxMeta || {},
    alias: passwordJWE1zycUbxMeta?.alias || [],
    redirect: passwordJWE1zycUbxMeta?.redirect,
    component: () => import("/app/pages/[language]/account/password.vue").then(m => m.default || m)
  },
  {
    name: registerwN7J2Bcl58Meta?.name ?? "language-account-register",
    path: registerwN7J2Bcl58Meta?.path ?? "/:language()/account/register",
    meta: registerwN7J2Bcl58Meta || {},
    alias: registerwN7J2Bcl58Meta?.alias || [],
    redirect: registerwN7J2Bcl58Meta?.redirect,
    component: () => import("/app/pages/[language]/account/register.vue").then(m => m.default || m)
  },
  {
    name: indexkimvN0QfZkMeta?.name ?? "language-account-unsubscribe",
    path: indexkimvN0QfZkMeta?.path ?? "/:language()/account/unsubscribe",
    meta: indexkimvN0QfZkMeta || {},
    alias: indexkimvN0QfZkMeta?.alias || [],
    redirect: indexkimvN0QfZkMeta?.redirect,
    component: () => import("/app/pages/[language]/account/unsubscribe/index.vue").then(m => m.default || m)
  },
  {
    name: report036mnBh6zZMeta?.name ?? "language-account-unsubscribe-report",
    path: report036mnBh6zZMeta?.path ?? "/:language()/account/unsubscribe/report",
    meta: report036mnBh6zZMeta || {},
    alias: report036mnBh6zZMeta?.alias || [],
    redirect: report036mnBh6zZMeta?.redirect,
    component: () => import("/app/pages/[language]/account/unsubscribe/report.vue").then(m => m.default || m)
  },
  {
    name: claim_45couponK1SRXl4Ih4Meta?.name ?? "language-claim-coupon",
    path: claim_45couponK1SRXl4Ih4Meta?.path ?? "/:language()/claim-coupon",
    meta: claim_45couponK1SRXl4Ih4Meta || {},
    alias: claim_45couponK1SRXl4Ih4Meta?.alias || [],
    redirect: claim_45couponK1SRXl4Ih4Meta?.redirect,
    component: () => import("/app/pages/[language]/claim-coupon.vue").then(m => m.default || m)
  },
  {
    name: indexf29a4ekf9KMeta?.name ?? "language-deals-slug",
    path: indexf29a4ekf9KMeta?.path ?? "/:language()/deals/:slug()",
    meta: indexf29a4ekf9KMeta || {},
    alias: indexf29a4ekf9KMeta?.alias || [],
    redirect: indexf29a4ekf9KMeta?.redirect,
    component: () => import("/app/pages/[language]/deals/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: details1FmwBHFAceMeta?.name ?? "language-deals-slug-order-details",
    path: details1FmwBHFAceMeta?.path ?? "/:language()/deals/:slug()/order/details",
    meta: details1FmwBHFAceMeta || {},
    alias: details1FmwBHFAceMeta?.alias || [],
    redirect: details1FmwBHFAceMeta?.redirect,
    component: () => import("/app/pages/[language]/deals/[slug]/order/details.vue").then(m => m.default || m)
  },
  {
    name: finishedxAcTXOQKhnMeta?.name ?? "language-deals-slug-order-finished",
    path: finishedxAcTXOQKhnMeta?.path ?? "/:language()/deals/:slug()/order/finished",
    meta: finishedxAcTXOQKhnMeta || {},
    alias: finishedxAcTXOQKhnMeta?.alias || [],
    redirect: finishedxAcTXOQKhnMeta?.redirect,
    component: () => import("/app/pages/[language]/deals/[slug]/order/finished.vue").then(m => m.default || m)
  },
  {
    name: indexGZ2CtzegwyMeta?.name ?? "language-deals-slug-order",
    path: indexGZ2CtzegwyMeta?.path ?? "/:language()/deals/:slug()/order",
    meta: indexGZ2CtzegwyMeta || {},
    alias: indexGZ2CtzegwyMeta?.alias || [],
    redirect: indexGZ2CtzegwyMeta?.redirect,
    component: () => import("/app/pages/[language]/deals/[slug]/order/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsHOwNJhCx6yMeta?.name ?? "language-deals-slug-reviews",
    path: reviewsHOwNJhCx6yMeta?.path ?? "/:language()/deals/:slug()/reviews",
    meta: reviewsHOwNJhCx6yMeta || {},
    alias: reviewsHOwNJhCx6yMeta?.alias || [],
    redirect: reviewsHOwNJhCx6yMeta?.redirect,
    component: () => import("/app/pages/[language]/deals/[slug]/reviews.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9379r4IcNSrkMeta?.name ?? "language-help-slug",
    path: _91slug_9379r4IcNSrkMeta?.path ?? "/:language()/help/:slug()",
    meta: _91slug_9379r4IcNSrkMeta || {},
    alias: _91slug_9379r4IcNSrkMeta?.alias || [],
    redirect: _91slug_9379r4IcNSrkMeta?.redirect,
    component: () => import("/app/pages/[language]/help/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexNLQIHJg711Meta?.name ?? "language-help",
    path: indexNLQIHJg711Meta?.path ?? "/:language()/help",
    meta: indexNLQIHJg711Meta || {},
    alias: indexNLQIHJg711Meta?.alias || [],
    redirect: indexNLQIHJg711Meta?.redirect,
    component: () => import("/app/pages/[language]/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexS5WyhbOij0Meta?.name ?? "language",
    path: indexS5WyhbOij0Meta?.path ?? "/:language()",
    meta: indexS5WyhbOij0Meta || {},
    alias: indexS5WyhbOij0Meta?.alias || [],
    redirect: indexS5WyhbOij0Meta?.redirect,
    component: () => import("/app/pages/[language]/index.vue").then(m => m.default || m)
  },
  {
    name: nuvei_45helperSjbhIKNqixMeta?.name ?? "language-nuvei-helper",
    path: nuvei_45helperSjbhIKNqixMeta?.path ?? "/:language()/nuvei-helper",
    meta: nuvei_45helperSjbhIKNqixMeta || {},
    alias: nuvei_45helperSjbhIKNqixMeta?.alias || [],
    redirect: nuvei_45helperSjbhIKNqixMeta?.redirect,
    component: () => import("/app/pages/[language]/nuvei-helper.vue").then(m => m.default || m)
  },
  {
    name: indexeDFrCJmGWCMeta?.name ?? "language-onboard",
    path: indexeDFrCJmGWCMeta?.path ?? "/:language()/onboard",
    meta: indexeDFrCJmGWCMeta || {},
    alias: indexeDFrCJmGWCMeta?.alias || [],
    redirect: indexeDFrCJmGWCMeta?.redirect,
    component: () => import("/app/pages/[language]/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsnChuxWrFC1Meta?.name ?? "language-onboard-notifications",
    path: notificationsnChuxWrFC1Meta?.path ?? "/:language()/onboard/notifications",
    meta: notificationsnChuxWrFC1Meta || {},
    alias: notificationsnChuxWrFC1Meta?.alias || [],
    redirect: notificationsnChuxWrFC1Meta?.redirect,
    component: () => import("/app/pages/[language]/onboard/notifications.vue").then(m => m.default || m)
  },
  {
    name: indexkGe0ECwdXVMeta?.name ?? "language-outspot-plus",
    path: indexkGe0ECwdXVMeta?.path ?? "/:language()/outspot-plus",
    meta: indexkGe0ECwdXVMeta || {},
    alias: indexkGe0ECwdXVMeta?.alias || [],
    redirect: indexkGe0ECwdXVMeta?.redirect,
    component: () => import("/app/pages/[language]/outspot-plus/index.vue").then(m => m.default || m)
  },
  {
    name: detailsdt90XU3RNkMeta?.name ?? "language-outspot-plus-order-details",
    path: detailsdt90XU3RNkMeta?.path ?? "/:language()/outspot-plus/order/details",
    meta: detailsdt90XU3RNkMeta || {},
    alias: detailsdt90XU3RNkMeta?.alias || [],
    redirect: detailsdt90XU3RNkMeta?.redirect,
    component: () => import("/app/pages/[language]/outspot-plus/order/details.vue").then(m => m.default || m)
  },
  {
    name: finishedADdDBx7oc1Meta?.name ?? "language-outspot-plus-order-finished",
    path: finishedADdDBx7oc1Meta?.path ?? "/:language()/outspot-plus/order/finished",
    meta: finishedADdDBx7oc1Meta || {},
    alias: finishedADdDBx7oc1Meta?.alias || [],
    redirect: finishedADdDBx7oc1Meta?.redirect,
    component: () => import("/app/pages/[language]/outspot-plus/order/finished.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: readywgYXjzIKUXMeta?.name ?? "ready",
    path: readywgYXjzIKUXMeta?.path ?? "/ready",
    meta: readywgYXjzIKUXMeta || {},
    alias: readywgYXjzIKUXMeta?.alias || [],
    redirect: readywgYXjzIKUXMeta?.redirect,
    component: () => import("/app/pages/ready.vue").then(m => m.default || m)
  }
]