import sha1 from 'js-sha1'

const longterm = useLongtermStorage()

function isCacheDisabled() {
  let isDisabled = false

  // Disable cache for test products
  isDisabled =
    isDisabled || (globalThis.location?.href.includes('/deals/test/') ?? false)

  // Update document body class
  document.body.classList.toggle('body--cache-disabled', isDisabled)

  return isDisabled
}

function hashKey(key: string): string {
  return sha1(key)
}

function initCache(): void {
  // make sure we have cache vars
  longterm.cache = longterm.cache ?? {}
  longterm.cacheTTL = longterm.cacheTTL ?? {}

  // clear all expired vlues
  const now = new Date().getTime()

  Object.keys(longterm.cache).forEach((key) => {
    if ((longterm.cacheTTL[key] ?? 0) < now) {
      delete longterm.cacheTTL[key]
      delete longterm.cache[key]
    }
  })
}

export default () => {
  return {
    get: (key: string) => {
      // disabled cache?
      if (isCacheDisabled()) {
        return undefined
      }

      // make sure we have the cache vars
      initCache()

      // hash the key
      key = hashKey(key)

      // return the data
      return longterm.cache[key] ?? undefined
    },
    set: (key: string, value: any, ttl?: number) => {
      // hash the key
      key = hashKey(key)

      // make sure we have the cache vars
      initCache()

      // store the new value
      const ttlTime = new Date().getTime() + (ttl ?? 60 * 60 * 1000)

      longterm.cacheTTL[key] = ttlTime
      longterm.cache[key] = value
    },
    has: (key: string) => {
      // hash the key
      key = hashKey(key)

      // make sure we have the cache vars
      initCache()

      // return the data
      return longterm.cache[key] !== undefined
    },
  }
}
